fieldset {
    padding: 0;
    margin: 0;
    border-radius: .35rem;
    font-size: .6rem;
    min-width: 70px;
}

fieldset p {
    padding: .1rem .35rem;
    margin: 0;
    font-size: .85rem;
    text-align: center;
}

.soldeContainer {
    padding: .3rem;
    text-align: center;
    border-radius: .5rem;
    margin: .25rem .5rem .25rem .5rem;
}

.overDraw {
    color: red;
    font-weight: 300;
    font-size: .7rem;
    margin: 0;
    text-align: center;
}

