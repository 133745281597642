/* Définissez les styles pour la disposition sur une seule ligne pour les écrans larges */
@media (min-width: 768px) {
  .flex-container {
    display: flex;
    flex-direction: row; /* Disposition en ligne */
    width: 100%; /* Assurez-vous d'occuper 100% de la largeur */
  }

  .flex-container > * {
    flex: 1; /* Diviser l'espace en deux pour chaque élément enfant */
    margin-right: 8px; /* Ajoutez un espacement entre les éléments */
  }

  .flex-container > *:last-child {
    margin-right: 0; /* Pas de marge à droite pour le dernier élément */
  }
}
